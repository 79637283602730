import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crypto kdf hash`}</strong>{` -- derive a secret key from a secret value (e.g., a password)`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crypto kdf hash [<input>]
[--alg ALGORITHM]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crypto kdf hash`}</strong>{` uses a key derivation function (KDF) to produce a
pseudorandom secret key based on some (presumably secret) input value. This is
useful for password verification approaches based on password hashing. Key
derivation functions are designed to be computationally intensive, making it
more difficult for attackers to perform brute-force attacks on password
databases.`}</p>
    <p>{`If this command is run without the optional `}<inlineCode parentName="p">{`input`}</inlineCode>{` argument and STDIN is a TTY
(i.e., you're running the command in an interactive terminal and not piping
input to it) you'll be prompted to enter a value on STDERR. If STDIN is not a
TTY it will be read without prompting.`}</p>
    <p>{`This command will produce a string encoding of the KDF output along with the
algorithm used, salt, and any parameters required for validation in PHC string
format.`}</p>
    <p>{`The KDFs are run with parameters that are considered safe. The 'scrypt'
parameters are currently fixed at N=32768, r=8 and p=1. The 'bcrypt' work
factor is currently fixed at 10.`}</p>
    <p>{`For examples, see `}<strong parentName="p">{`step help crypto kdf`}</strong>{`.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`input`}</inlineCode>{`
The input to the key derivation function. `}<inlineCode parentName="p">{`input`}</inlineCode>{` is optional and its use is
not recommended. If this argument is provided the `}<strong parentName="p">{`--insecure`}</strong>{` flag must also
be provided because your (presumably secret) `}<inlineCode parentName="p">{`input`}</inlineCode>{` will likely be logged and
appear in places you might not expect. If omitted input is read from STDIN.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--alg`}</strong>{`=`}<inlineCode parentName="p">{`algorithm`}</inlineCode>{`
The KDF algorithm to use.`}</p>
    <p><inlineCode parentName="p">{`algorithm`}</inlineCode>{` must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`scrypt`}</strong>{`: A password-based KDF designed to use exponential time and memory.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`bcrypt`}</strong>{`: A password-based KDF designed to use exponential time.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`argon2i`}</strong>{`: A password-based KDF optimized to resist side-channel attacks.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`argon2id`}</strong>{`: A password-based KDF optimized to resist GPU and side-channel attacks.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      